import Business from '@/types/business';
import User from '@/types/user';

import LogRocket from 'logrocket';

export const updateLogRocketTracking = ( user: User, business: Business ) => {
    if ( !user || !user.email ) {
        console.error( 'No user to track in LogRocket' );
        return;
    }

    if ( window
        && window.location
        && ( window.location.hostname === 'company.veryableops.com'
            || window.location.hostname === 'portal.veryableops.com'
        ) ) {
        try {
            LogRocket.identify( user.id.toString(), {
                name: user.firstName && user.lastName ? user.firstName.toString().concat( ' ', user.lastName.toString() ) : ''
                , email: user.email ? user.email : ''
                , businessUserId: user.businessUserId ? user.businessUserId : ''
                , isInternalUser: user.isInternalUser ? user.isInternalUser : ''
                , internalDistrict: user.internalDistrict ? user.internalDistrict : ''
                , businessName: business.businessName ? business.businessName : ''
                , businessId: business.id ? business.id : ''
                , createdAt: user.createdAt ? user.createdAt : ''
                , postedOps: business.postedOpsCountAllTime ? business.postedOpsCountAllTime : ''
            } );

        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error adding LogRocket user tracking info - ${ e.message }.` );
            }
        }
    } else if ( window
        && window.location
        && ( window.location.hostname === 'companyqa.veryableops.com'
            || window.location.hostname === 'portalqa.veryableops.com'
        ) ) {
        try {
            LogRocket.identify( user.id.toString(), {
                name: user.firstName && user.lastName ? user.firstName.toString().concat( ' ', user.lastName.toString() ) : ''
                , email: user.email ? user.email : ''
                , businessUserId: user.businessUserId ? user.businessUserId : ''
                , isInternalUser: user.isInternalUser ? user.isInternalUser : ''
                , internalDistrict: user.internalDistrict ? user.internalDistrict : ''
                , businessName: business.businessName ? business.businessName : ''
                , businessId: business.id ? business.id : ''
                , createdAt: user.createdAt ? user.createdAt : ''
                , postedOps: business.postedOpsCountAllTime ? business.postedOpsCountAllTime : ''
            } );

        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error adding LogRocket user tracking info - ${ e.message }.` );
            }
        }
    } else {
        try {
            LogRocket.identify( user.id.toString(), {
                name: user.firstName && user.lastName ? user.firstName.toString().concat( ' ', user.lastName.toString() ) : ''
                , email: user.email ? user.email : ''
                , businessUserId: user.businessUserId ? user.businessUserId : ''
                , isInternalUser: user.isInternalUser ? user.isInternalUser : ''
                , internalDistrict: user.internalDistrict ? user.internalDistrict : ''
                , businessName: business.businessName ? business.businessName : ''
                , businessId: business.id ? business.id : ''
                , createdAt: user.createdAt ? user.createdAt : ''
                , postedOps: business.postedOpsCountAllTime ? business.postedOpsCountAllTime : ''
            } );

        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error adding LogRocket user tracking info - ${ e.message }.` );
            }
        }
    }

};

export const logRocketException = ( error: Error, tag: string ) => {
    const exception = new Error( `${ tag } - ${ error.message }`, { cause: error.cause } );
    exception.name = error.name;
    exception.stack = error.stack;
    return exception;
};
