import { CSSProperties } from 'react';

// MUI
import { createTheme } from '@mui/material/styles';

// Styles
import {
    darkGray
    , linearGradient
    , veryableBlue
} from '@/theme/globalStyles';

// Utils
import { pxrem } from '@/utils/pxrem';

const textFontSize = '0.75rem';

type CssColorProperties = NonNullable<CSSProperties['color']>;

declare module '@mui/material/styles' {

    interface PaletteColor {
        highlight?: string;
    }

    interface SimplePaletteColorOptions {
        highlight?: string;
    }

    export interface TypeBackground {
        default: string;
        paper: string;
        header: string;
    }

    interface TypographyVariants {
        caption: CSSProperties;
        finePrint: CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        caption?: CSSProperties;
        finePrint?: CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        caption: true;
        finePrint: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gray: true;
    }
}

export const themeV2 = createTheme( {
    palette: {
        primary: {
            main: '#2081C3'
            , dark: '#0B6CAE'
            , light: '#6DACD7'
            , highlight: '#E3EDF3'
            , contrastText: '#FFFFFF'
        }
        , secondary: {
            main: '#8C7AD9'
            , dark: '#6451B6'
            , light: '#BBB0E8'
            , highlight: '#EEEBF9'
            , contrastText: '#FFFFFF'
        }
        , tertiary: {
            main: '#68AAAB'
            , dark: '#328A8B'
            , light: '#8CC5C6'
            , highlight: '#EEF6F6'
            , contrastText: '#FFFFFF'
            , bannerBg: '#EEF6F6'
        }
        , orange: {
            main: '#F26622'
            , dark: '#CC4C0A'
            , light: '#FC8D55'
            , highlight: '#FEE2D4'
            , contrastText: '#FFFFFF'
        }
        , red: {
            main: '#E91D29'
            , dark: '#B8121C'
            , light: '#EA6872'
            , highlight: '#FDECED'
            , contrastText: '#FFFFFF'
        }
        , gray: {
            main: '#4A4A4A'
            , secondary: '#7E7E7E'
            , disabled: '#C3C3C3'
            , divider: '#ECECEC'
            , titles: '#ADADAD'
            , section: '#F5F5F5'
            , hover: '#FAFAFA'
        }
        , common: {
            white: '#FFFFFF'
            , black: '#141414'
        }
        , background: {
            default: '#F1F5F6'
            , header: linearGradient
        }
    }
    , typography: {
        fontFamily: [
            'Avenir Next'
            , 'Open Sans'
            , 'sans-serif'
        ].join( ',' )
        , fontSize: 14
        , h1: {
            color: veryableBlue
            , fontSize: pxrem( 30 )
            , fontWeight: 600
        }
        , h2: {
            color: darkGray
            , fontSize: pxrem( 24 )
            , fontWeight: 600
        }
        , h3: {
            color: darkGray
            , fontSize: pxrem( 20 )
            , fontWeight: 600
        }
        , h4: {
            fontSize: pxrem( 18 )
            , fontWeight: 600
            , lineHeight: pxrem( 26 )
            , color: darkGray
        }
        , h5: {
            fontSize: pxrem( 16 )
            , fontWeight: 600
            , lineHeight: pxrem( 24 )
            , letterSpacing: '0em'
            , textAlign: 'left'
        }
        , h6: {
            color: darkGray
            , fontSize: pxrem( 14 )
            , fontWeight: 600
        }
        , button: {
            fontWeight: 600
        }
        , body1: {
            fontSize: pxrem( 16 )
        }
        , body2: {
            fontSize: pxrem( 14 )
        }
        , caption: {
            fontSize: pxrem( 12 )
        }
        , finePrint: {
            fontSize: pxrem( 10 )
        }
        , subtitle3: {
            fontSize: pxrem( 12 )
            , fontWeight: 500
        }
        , subtitle4: {
            fontSize: pxrem( 22 )
            , fontWeight: 500
            , lineHeight: pxrem( 19 )
        }
        , subtitle5: {
            fontSize: pxrem( 20 )
            , fontWeight: 500
            , lineHeight: pxrem( 32 )
            , letterSpacing: pxrem( 0.15 )
        }
    }
} );
