'use client';

import React from 'react';

// Libraries
import LogRocket from 'logrocket';
import Image from 'next/legacy/image';

// MUI
import {
    Button
    , Stack
    , ThemeProvider
    , Typography
} from '@mui/material';
import { themeV2 } from '@/theme/theme-v2';

// Utils
import { pxrem } from '@/utils/pxrem';
import { logRocketException } from '@/utils/logrocket';

export interface Props {
    error: Error;
    resetErrorBoundary: () => void;
    showBackground?: boolean;
}

const ErrorFallback = ( {
    error
    , resetErrorBoundary
    , showBackground = false
}: Props ) => {
    console.error( `Error Fallback ${ error.message }` );

    LogRocket.captureException(
        logRocketException( error, 'Component Error Fallback' )
        , { tags: { component: 'Error Fallback' } }
    );

    const handleContact = React.useCallback( () => {
        if ( window.zE ) {
            window.zE( 'webWidget', 'toggle' );
        }
    }, [] );

    React.useEffect( () => {
        if ( window.zE ) {
            window.zE( 'webWidget', 'show' );
        }
    }, [] );

    return (
        <ThemeProvider theme={ themeV2 }>
            <Stack
                sx={ {
                    gap: pxrem( 16 )
                    , paddingY: pxrem( 30 )
                    , justifyContent: 'center'
                    , alignItems: 'center'
                    , width: '100%'
                    , backgroundColor: theme => showBackground ? theme.palette.common.white : undefined
                } }
            >
                <Image
                    src='error-fallback-image.png'
                    alt='Error Fallback Banner'
                    width={ 215 }
                    height={ 183 }
                />
                <Typography
                    variant='h3'
                    sx={ {
                        marginTop: pxrem( 16 )
                        , color: theme => theme.palette.gray.secondary
                    } }
                >
                    Oops!
                </Typography>
                <Typography
                    variant='body1'
                    sx={ {
                        textAlign: 'center'
                        , marginTop: pxrem( -4 )
                        , maxWidth: pxrem( 400 )
                        , color: theme => theme.palette.gray.secondary
                    } }
                >
                    Something went wrong on our end and we encountered an error. Please try again.
                </Typography>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={ () => {
                        if ( window ) {
                            window.location.reload();
                        }
                    } }
                >
                    Retry
                </Button>
                {
                    window.zE
                        ? (
                            <Button
                                variant='text'
                                color='primary'
                                onClick={ handleContact }
                            >
                            Chat with Support
                            </Button>
                        )
                        : (
                            <Button
                                variant='text'
                                color='primary'
                                onClick={ () => {
                                    if ( window ) {
                                        window.location.href = 'mailto:support@veryableops.com';
                                    }
                                } }
                            >
                                Email Support
                            </Button>
                        )
                }
            </Stack>
        </ThemeProvider>
    );
};

export default ErrorFallback;
