// Add global variables here;
import { FontWeight } from '@/types/csstypes';
import { pxrem } from '@/utils/pxrem';

//color palette
export const veryableGreen = '#68AAAB';
export const veryableGreenHover = '#328A8B';
export const veryableGreenDisabled = '#8CC5C6';

export const veryableBlue = '#2081C3';
export const veryableBlueHover = '#0B6CAE';
export const veryableBlueDisabled = '#6DACD7';
export const veryableLightBlue = '#FAFDFF';
export const veryableBlueHighlight = '#DDEDF7';
export const veryableBlueOpaque = 'rgba(109, 172, 215, 0.25)';
export const veryableBlueBackgroundOpaque = '#DAEAF5';
export const veryableBlueBackgroundOpaque2 = '#D5E4EE';

export const veryablePurple = '#8C7AD9';
export const veryablePurpleHover = '#6451B6';
export const veryablePurpleOpaque = 'rgba(187, 176, 232, 0.25)';
export const veryablePurpleDisabled = '#BBB0E8';
export const veryablePurpleExtraDark = '#482877';

export const veryablePink = '#D363A8';
export const veryablePinkHover = '#B5458A';
export const veryablePinkDisabled = '#E89ECB';

export const veryableBrightGreen = '#5F9B19';
export const veryableBrightGreenHover = '#4A800C';
export const veryableBrightGreenDisabled = '#8AC842';
export const veryableBrightGreenAccent = '#9CE446';

export const veryableDarkGreen = '#30660F';

export const veryableRed = '#E91D29';
export const veryableRedHover = '#B8121C';
export const veryableRedDisabled = '#EA6B72';
export const veryableRedBackground = '#F1B3B7';

export const veryableOrange = '#F96519';
export const veryableOrangeHover = '#CC4C0A';
export const veryableOrangeDisabled = '#FC8D55';
export const veryableOrangeBackground = '#FBC6AB';

export const veryableYellow = '#F5A623';
export const veryableYellowHover = '#E08C01';
export const veryableYellowDisabled = '#F9C163';

export const veryableBrightBlue = '#02A2D6';

export const veryableBlack = '#000000';
export const veryableWhite = '#ffffff';

export const veryablePurpleAccent = '#AC70C3';

export const veryablePinkAccent = '#CA579C';
export const veryablePinkLightAccent = '#F7AFFF';

export const darkGray = '#4A4A4A';
export const veryableGray = '#7E7E7E';
export const veryableGrayDisabled = '#C3C3C3';
export const veryableGrayHover = '#C3C3C3';
export const hoverBackground = '#ECECEC';
export const lightGrayAccent = '#F1F5F6';
export const dividerGray = '#D8D8D8';
export const headerBorderGray = '#979797';
export const placeholderGray = '#A4A4A4';
export const grayAccent = '#F5F5F5';
export const lightGray = '#EEEEEE';
export const extraLightGray = '#F9F9F9';
export const altDivider = '#E0E0E0';

export const starRatingColor = '#F5A623';
export const emptyStarRatingColor = '#999';
export const selectedBackgroundColor = '#ecf4fa';

//action colors
export const adjustHoursColor = '#2081C3';
export const bidActivityColor = '#68AAAB';
export const ratingColor = '#8C7AD9';
export const paidColor = '#5F9B19';
export const raiseColor = '#D363A8';
export const cancelledColor = '#F96519';
export const disputedColor = '#E91D29';
export const unfulfilledColor = '#F5A623';
export const inactiveColor = '#7E7E7E';
export const whiteColor = '#FFFFFF';

export const drawerWidth = 220;
export const collapsedDrawerWidth = 60;

export const hvdTableHeight = 'calc(100vh - 315px)';
export const hvdTableHeightWithoutNav = 'calc(100vh - 110px)';

export const transition = {
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
};

export const sidebarTransition = 'width 225ms ease-out';

export const zIndexGreaterThanFreshcat = 9999999999;

export const container = {
    paddingRight: 15
    , paddingLeft: 15
    , marginRight: 'auto'
    , marginLeft: 'auto'
};

export const boxShadow = {
    boxShadow:
        '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
};

export const newBoxShadow = '0 1px 3px 0 rgba(0, 0, 0, 0.3)';

export const newCircleIconShadow = '0px 3px 1px rgba(0, 0, 0, 0.01), 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.09), 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);';

export const lightBorderGray = 'rgba(126,126,126,0.5)';

export const card = {
    display: 'inline-block'
    , position: 'relative'
    , width: '100%'
    , margin: '25px 0'
    , boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
    , borderRadius: 3
    , color: 'rgba(0, 0, 0, 0.87)'
    , background: '#fff'
};

export const defaultFont = {
    // fontFamily: '"Nunito", Open Sans", "Helvetica", "Arial", sans-serif'
    fontFamily: '"Open Sans", sans-serif'
    , fontWeight: 300 as FontWeight
    , lineHeight: '1.5em'
};

export const displayFont = {
    fontFamily: '"Passion One", "Open Sans", sans-serif'
    , fontWeight: 700 as FontWeight
    , fontSize: '2rem'
};

export const header = {
    fontFamily: 'Comfortaa'
    , fontWeight: 'bold' as FontWeight
    , color: '#2081C3'
};

export const tableWrapper = {
    background: veryableWhite
    , boxShadow: newBoxShadow
    , paddingLeft: 16
    , paddingRight: 16
    , height: 400
    , marginBottom: 25
};

export const subHeader = {
    fontFamily: 'Avenir Next, Lato, sans-serif'
    , fontWeight: 'normal' as FontWeight
    , color: '#7E7E7E'
};

export const bodyText = {
    fontFamily: 'Avenir Next, Lato, sans-serif'
    , fontWeight: 'normal' as FontWeight
    , color: '#7E7E7E'
};

export const tableHeaderTitle = {
    // fontSize: 18
};

export const tabsBoxShadow = {
    boxShadow:
        '1px 2px 3px 0px rgba(0,0,0,0.30)'
};

export const defaultBoxShadow = {
    border: 0
    , borderRadius: 3
    , boxShadow:
        '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
    , padding: '10px 0'
    , transition: 'all 150ms ease 0s'
};

export const row = {
    display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'center'
};

export const containerHeader = {
    margin: '0 0 5px'
    , fontSize: '1.5625rem'
    , fontWeight: 600 as FontWeight
    , color: '#7b7a7a'
};

export const containerSubHeader = {
    ...containerHeader
    , margin: '0 0 5px'
    , fontSize: '1.125rem'
    , fontWeight: 400 as FontWeight
};

export const containerSubHeaderSmall = {
    ...containerHeader
    , margin: '0 0 5px'
    , fontSize: '0.9rem'
    , fontWeight: 400 as FontWeight
    , color: veryableGray
};

export const button = {
    fontFamily: 'Avenir Next, Lato, sans-serif'
    , fontWeight: 600 as FontWeight
    , fontColor: '#FFFFFF'
    , height: 35
    , round: 2
};

export const tabContainer = {
    height: 40
    , display: 'flex'
    , paddingTop: 8
    , width: '60vw'
};

export const tab = {
    color: '#88C4EE'
    , width: 110
    , '&:hover': {
        cursor: 'pointer'
        , color: '#ffffff'
    }
    , '&$selectedTab': {
        color: '#ffffff'
        , fontWeight: 600 as FontWeight
        , borderBottom: `3px solid ${ veryableWhite }`
    }
};

export const dashboardTab = {
    color: '#88C4EE'
    , width: pxrem( 130 )
    , '&:hover': {
        cursor: 'pointer'
        , color: '#ffffff'
    }
    , '&$selectedTab': {
        color: '#ffffff'
        , fontWeight: 600 as FontWeight
        , borderBottom: `3px solid ${ veryableWhite }`
    }
};

export const sectionHeader = {
    display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'space-between'
    , marginTop: 24
    , marginBottom: 24
    , paddingBottom: 5
    , borderBottom: `0.5px solid ${ headerBorderGray }`
};

export const closeIconContainer = {
    display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'flex-end'
    , paddingRight: 5
    , fontSize: 14
    , cursor: 'pointer'
    , '&:hover': {
        color: darkGray
    }
    , transition: 'all 0.25s'
};

export const pinkToPurpleBackgroundImage = {
    backgroundImage: `linear-gradient(to right, ${ veryablePink }, ${ veryablePurple })`
};

export const linearPinkToPurple = `linear-gradient(to right, ${ veryablePink }, ${ veryablePurple })`;

export const linearGradient = `linear-gradient(to top right, ${ veryableBlue }, ${ veryableGreen })`;

export const gradientBlueToPurple = `linear-gradient(to right, ${ veryableBlue }, ${ veryablePurpleHover })`;

export const textFieldFormElementMargin = {
    marginBottom: '0.8rem'
};

// RGBA Values

export const redHighlight = 'rgba(234, 104, 114, 0.25)';
export const greenHighlight = 'rgba(140, 197, 198, 0.25)';
